import React from "react";
import "./QualityS.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImg1 from "../../Assets/Images/Quality/Slide1.png";
import sliderImg2 from "../../Assets/Images/Quality/Comparator.png";
import sliderImg3 from "../../Assets/Images/Quality/Hardness.png";
import sliderImg7 from "../../Assets/Images/Quality/New-VMM.jpg";
import sliderImg8 from "../../Assets/Images/Quality/OptimalNew.png";
import Translation from "../../i18n";

const QualityS = () => {
  const { t } = Translation();
  const settings = {
    // Customize your settings here
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="quality-slider-section-last mt-5 mb-5">
            <div className="section-header">
              <div className="section-heading">
                <h3> {t("Modern-measuring")} </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center quality-slider-section">
          <Slider {...settings}>
            <div className="row-slider-img mx-5">
              <img src={sliderImg1} alt="img" style={{ width: "100%" }} />
              <h3 className="mt-3">{t("CMM")}</h3>
            </div>

            <div className="row-slider-img mx-5">
              <img src={sliderImg7} alt="img" style={{ width: "100%" }} />
              <h3 className="mt-3">{t("VMM")}</h3>
            </div>

            <div className="row-slider-img mx-5">
              <img src={sliderImg8} alt="img" style={{ width: "100%" }} />
              <h3 className="mt-3">{t("Gauge")}</h3>
            </div>

            <div className="row-slider-img mx-5">
              <img src={sliderImg2} alt="img" style={{ width: "100%" }} />
              <h3 className="mt-3">{t("Comparator-Stand")}</h3>
            </div>

            <div className="row-slider-img mx-5">
              <img src={sliderImg3} alt="img" style={{ width: "100%" }} />
              <h3 className="mt-3">{t("Hardness")}</h3>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default QualityS;
