import React from "react";
import "./Infrastructure.css";
import Infra from "../../Assets/Images/Infra/InfraHome.jpg";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Newmachine1 from "../../Assets/Images/Infra/NewMachine1.jpg";
import newmach2 from "../../Assets/Images/Infra/NewMachine2.jpg";
import newmach3 from "../../Assets/Images/Infra/NewMachine3.jpg";
import newmach4 from "../../Assets/Images/Infra/NewMachine4.jpg";
import newmach5 from "../../Assets/Images/Infra/NewMachine5.jpg";
import Newmachine2 from "../../Assets/Images/Infra/machine2.png";
import Newmachine3 from "../../Assets/Images/Infra/machine3.png";
import Newmachine4 from "../../Assets/Images/Infra/machine4.png";
import Newmachine5 from "../../Assets/Images/Infra/machine5.png";
import Newmachine6 from "../../Assets/Images/Infra/machine6.png";
import Newmachine7 from "../../Assets/Images/Infra/machine7.png";
import Newmachine8 from "../../Assets/Images/Infra/machine8.png";
import Newmachine9 from "../../Assets/Images/Infra/machine9.png";
import Newmachine10 from "../../Assets/Images/Infra/machine10.png";
import Newmachine11 from "../../Assets/Images/Infra/machine11.png";
import Newmachine12 from "../../Assets/Images/Infra/machine12.png";

const Infrastructure = () => {
  const navigate = useNavigate();
  const { t } = Translation();

  const infrastructureJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/infrastructure",
    url: "https://senwellexports.com/infrastructure",
    name: "Infrastructure at Senwell Exports Pvt Ltd - Our Facilities",
    description:
      "Explore Senwell Exports Pvt Ltd’s advanced infrastructure, including top facilities, equipment, and technologies that drive our manufacturing excellence.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Infrastructure at Senwell Exports Pvt Ltd - Our Facilities
        </title>
        <meta
          name="description"
          content="Explore Senwell Exports Pvt Ltd’s advanced infrastructure, including top facilities, equipment, and technologies that drive our manufacturing excellence."
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/infrastructure"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Machining Set Up" />
        <meta
          property="og:description"
          content="We utilize state-of-the-art automated machinery, sourced from USA, Japan, and India, to achieve unparalleled precision and efficiency. This advanced technology allows us to seamlessly meet our customers' critical requirements on a large scale."
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/infrastructure"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/InfrastructureBanner.ed77aaf0759b7292f42f.png"
        />
        <meta property="og:image:width" content="1519" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(infrastructureJsonLd)}
        </script>
      </Helmet>
      <div>
        <div className="Infrastructure-section-home">
          <div className="infrastructure-img">
            <img src={Infra} alt="img" />
          </div>
          <div className="text-Infra">
            <h1>{t("Infrastructure")}</h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <article className="content span12">
              <div className="row">
                <div className="span12 sepage-header">
                  <h1>{t("Machining-Set")}</h1>
                  <p align="justify">{t("Machining Set Text")}</p>
                </div>
                <p>&nbsp;</p>
              </div>
            </article>
          </div>
        </div>

        <section className="Unit-Sections">
          <div className="container px-4">
            <h1>{t("Innovative")}</h1>
            <p>{t("Innovative-text")}</p>
            <div className="container">
              <div className="row g-4">
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine1}
                    alt="Newmachine1"
                    className="Machine-image"
                  />
                  <p className="exe-text">Shop Floor</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={newmach2}
                    alt="Newmachine1"
                    className="Machine-image"
                  />
                  <p className="exe-text">Special Purpose Machine</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={newmach3}
                    alt="Newmachine1"
                    className="Machine-image"
                  />
                  <p className="exe-text">Turning Center Unit</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={newmach4}
                    alt="Newmachine1"
                    className="Machine-image"
                  />
                  <p className="exe-text">Turning & Milling Center Unit</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={newmach5}
                    alt="Newmachine1"
                    className="Machine-image"
                  />
                  <p className="exe-text">Turning & Milling Center Unit</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine2}
                    alt="Newmachine2"
                    className="Machine-image"
                    onClick={() => navigate("/vmc3axismachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">Vertical Machinig Center 3-Axis</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine3}
                    alt="Newmachine3"
                    className="Machine-image"
                    onClick={() => navigate("/vmc4axismachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">Vertical Machinig Center 4-Axis</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine4}
                    alt="Newmachine4"
                    className="Machine-image"
                  />
                  <p className="exe-text">CNC Turning (LL20TM L5)</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine5}
                    alt="Newmachine5"
                    className="Machine-image"
                  />
                  <p className="exe-text">CNC Turning (LL30TM L10)</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine6}
                    alt="Newmachine6"
                    className="Machine-image"
                  />
                  <p className="exe-text">VMC 3Axis (J2)</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine7}
                    alt="Newmachine7"
                    className="Machine-image"
                    onClick={() => navigate("/cnc02machine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">CNC Turning Center 02</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine8}
                    alt="Newmachine8"
                    className="Machine-image"
                    onClick={() => navigate("/cmmmachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">Coordinate Measuring Machine</p>
                  <div className="hover-info-coordinate">
                    <ol className="custom-dot-list">
                      <li>
                        <span>Make</span> - Hexagon
                      </li>
                      <li>
                        <span>Model</span> - Global S <br /> &nbsp; &nbsp;
                        &nbsp; Green
                      </li>
                      <li>
                        <span>Probe Style</span> - Touch <br />
                        &nbsp; &nbsp; &nbsp; & Scanning
                      </li>
                      <li>
                        <span>Size</span>- X-700, Y-1000,
                        <br /> &nbsp; &nbsp; &nbsp; Z-700 mm.
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine9}
                    alt="Newmachine9"
                    className="Machine-image"
                    onClick={() => navigate("/vmmmachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">Vision Measuring Machine</p>
                  <div className="hover-info">
                    <ol className="custom-dot-list">
                      <li>
                        <span>Make</span> - Electronica
                      </li>
                      <li>
                        <span>Model</span> - I Vista
                      </li>
                      <li>
                        <span>Size</span>- X-200, Y-150,
                        <br /> &nbsp; &nbsp; &nbsp; Z-200 mm.
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine10}
                    alt="Newmachine10"
                    className="Machine-image"
                  />
                  <p className="exe-text">Special Purpose Machine</p>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine11}
                    alt="Newmachine11"
                    className="Machine-image"
                    onClick={() => navigate("/hmmmachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">2D Heigth Gauge</p>
                  <div className="hover-info">
                    <ol className="custom-dot-list">
                      <li>
                        <span>Make</span> - Electronica
                      </li>
                      <li>
                        <span>Model</span> - Optimal H <br />
                        &nbsp; &nbsp; &nbsp; 450
                      </li>
                      <li>
                        <span>Size</span>-450mmHeight
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="col-md-4 export-img">
                  <img
                    src={Newmachine12}
                    alt="Newmachine12"
                    className="Machine-image"
                    onClick={() => navigate("/srtmachine")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="exe-text">Surface Roughness Tester</p>
                  <div className="hover-info">
                    <ol className="custom-dot-list">
                      <li>
                        <span>Make</span> - Accretech
                      </li>
                      <li>
                        <span>Model</span> - Surfcome <br /> &nbsp; &nbsp;
                        &nbsp; Touch 550
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Infrastructure;
