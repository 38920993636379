import React, { useEffect } from "react";
import "./Service.css";
import serBg from "../../Assets/Images/service/service.png";
import assembly from "../../Assets/Images/service/assemblyHub.png";
import oem from "../../Assets/Images/service/OEM.png";
import contra from "../../Assets/Images/service/contra.png";
import offshore from "../../Assets/Images/service/offshore.png";
import service1 from "../../Assets/Images/service/services/service1.png";
import service2 from "../../Assets/Images/service/services/service2.png";
import service3 from "../../Assets/Images/service/services/service3.png";
import service4 from "../../Assets/Images/service/services/service4.png";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";

const Service = () => {
  const { t } = Translation();

  useEffect(() => {
    const ELEMENT_TO_NAVIGATE = window.location.hash.replace("#", "").trim();
    if (ELEMENT_TO_NAVIGATE) {
      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById(ELEMENT_TO_NAVIGATE)?.offsetTop,
          behavior: "smooth",
        });
      }, 100);
    }
  }, []);

  const servicesJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/service",
    url: "https://senwellexports.com/service",
    name: "Our Services | Senwell Exports Pvt Ltd - Precision & Quality",
    description:
      "Explore Senwell Exports services: precision machining, quality assurance, and custom solutions for aerospace, defense, energy, and oil & gas sectors.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Our Services | Senwell Exports Pvt Ltd - Precision & Quality
        </title>
        <meta
          name="description"
          content="Explore Senwell Exports services: precision machining, quality assurance, and custom solutions for aerospace, defense, energy, and oil & gas sectors."
        />
        <link rel="canonical" href="https://senwellexports.com/service" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="OEM Manufacturing" />
        <meta
          property="og:description"
          content="Senwell Exports offers a comprehensive and highly secure array of OEM manufacturing services, strategically designed to enhance product quality and augment business value."
        />
        <meta property="og:url" content="https://senwellexports.com/service" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/service3.7a70961ddbda13c6aab7.png"
        />
        <meta property="og:image:width" content="509" />
        <meta property="og:image:height" content="340" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(servicesJsonLd)}
        </script>
      </Helmet>

      <section className="service-section">
        <div className="service-bg-img">
          <img src={serBg} className="img-fluid" alt="Background"></img>
          <div className="text-overlay">
            <h1>{t("Our-Services")}</h1>
          </div>
        </div>
      </section>
      <div className="container mt-5 mb-5">
        <div className="row">
          {}
          <div className="col-md-12">
            <div className="slider">
              <div className="slide-track">
                <div className="image-slider">
                  <img src={assembly} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Assembly Hub")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={oem} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("OEM-Manufacturing")}</p>
                  </div>
                </div>

                <div className="image-slider">
                  <img src={contra} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Contract-Manufacturing")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={oem} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("OEM-Manufacturing")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={offshore} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Offshore-Manufacturing")}</p>
                  </div>
                </div>

                <div className="image-slider">
                  <img src={assembly} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Assembly Hub")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={oem} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("OEM-Manufacturing")}</p>
                  </div>
                </div>

                <div className="image-slider">
                  <img src={assembly} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Assembly Hub")}</p>
                  </div>
                </div>

                <div className="image-slider">
                  <img src={contra} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Contract-Manufacturing")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={offshore} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Offshore-Manufacturing")}</p>
                  </div>
                </div>
                <div className="image-slider">
                  <img src={assembly} height="111" width="191" alt="img" />
                  <div className="image-text-overlay">
                    <p>{t("Assembly Hub")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="oem-manufacturing">
        <div className="service-section-4">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-img">
                <img src={service3} alt="img" className="ser-left-img" />
              </div>
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-col">
                <h3 className="Ser-Header">{t("OEM-Manufacturing")}</h3>
                <div className="ss-text-inner">
                  {t("OEM-Manufacturing-Text")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="assembly-hub">
        <div className="service-section-2">
          <div className="container ">
            <div className="row oddSection">
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-col">
                <h3 className="Ser-Header">{t("Assembly Hub")}</h3>
                <div className="ss-text-inner"> {t("Assembly Hub-text")}</div>
              </div>
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-img">
                <img src={service1} alt="img" className="ser-left-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contraction-manufacturing">
        <div className="service-setion-3">
          <div className="container">
            <div className="row oddSection">
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-img">
                <img src={service2} alt="img" className="ser-left-img" />
              </div>
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-col">
                <h3 className="Ser-Header">{t("Contract-Manufacturing")}</h3>
                <div className="ss-text-inner">
                  {t("Contract-Manufacturing-text")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="offshore-manufacturing">
        <div className="service-section-5">
          <div className="container">
            <div className="row -">
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-col">
                <h3 className="Ser-Header">{t("Offshore-Manufacturing")}</h3>
                <div className="ss-text-inner">
                  {t("Offshore-Manufacturing-text")}
                </div>
              </div>
              <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-img">
                <img src={service4} alt="img" className="ser-left-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
