import React from "react";
import "./News1.css";
import { Helmet } from "react-helmet";

function News1() {
  const NewsArticleJsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "@id":
      "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    url: "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    headline: "GE Aerospace Advances the Development Collaboration with NASA",
    description:
      "GE Aerospace and NASA are advancing hybrid electric engines, driving innovations for a more sustainable future in aviation.",
    datePublished: "2024-06-24",
    mainEntityOfPage:
      "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    publisher: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        width: 82,
        height: 82,
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
    image: {
      "@type": "ImageObject",
      url: "https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG",
      width: 366,
      height: 200,
      caption: "GE Aerospace Advances the Development Collaboration with NASA",
    },
    keywords:
      "GE Aerospace, NASA, Aerospace Collaboration, Development News, Senwell Exports",
    articleSection: ["Aerospace News", "Industry Updates"],
    inLanguage: "en-US",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          GE Aerospace Advances the Development Collaboration with NASA
        </title>
        <meta
          name="description"
          content="GE Aerospace and NASA are advancing hybrid electric engines, driving innovations for a more sustainable future in aviation."
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="GE Aerospace advances the development of hybrid electric engines in collaboration with NASA."
        />
        <meta
          property="og:description"
          content=" GE Aerospace (GE.N) is taking significant strides in developing a hybrid electric engine aimed at powering next-generation narrow-body jets by the mid-2030s. This innovative engine, which integrates electric motor/generators within a high-bypass turbofan, could revolutionize aviation by reducing carbon emissions and fuel consumption."
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG"
        />
        <meta property="og:image:width" content="366" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:type" content="image/jpg" />
        <script type="application/ld+json">
          {JSON.stringify(NewsArticleJsonLd)}
        </script>
      </Helmet>
      <section className="first-news-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="news-heading">
                <h1>
                  GE Aerospace advances the development of hybrid electric
                  engines in collaboration with NASA.
                </h1>
              </div>
              <div className="news-img mt-4">
                <img
                  src="https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG"
                  class="img-fluid"
                  alt="Responsive image"
                />
                <div className="img-source">
                  <p> Image Source - GE Aerospace</p>
                </div>
                <div className="news-paragraph mt-5">
                  <div className="para-heading">
                    <h3>
                      GE Aerospace and NASA Advance Hybrid Electric Engine
                      Development for Next-Generation Aircraft
                    </h3>
                  </div>
                  <div className="news-paragraph mt-3">
                    <p>
                      <b>EVENDALE, Ohio, June 19 </b> -{" "}
                      <a
                        className="text-decoration-none"
                        target="_blank"
                        href="https://www.geaerospace.com/"
                      >
                        GE Aerospace
                      </a>{" "}
                      (GE.N) is taking significant strides in developing a
                      hybrid electric engine aimed at powering next-generation
                      narrow-body jets by the mid-2030s. This innovative engine,
                      which integrates electric motor/generators within a
                      high-bypass turbofan, could revolutionize aviation by
                      reducing carbon emissions and fuel consumption.
                    </p>
                    <p>
                      In collaboration with NASA, GE Aerospace is working on a
                      hybrid electric demonstrator engine as part of the Hybrid
                      Thermally Efficient Core (HyTEC) project. The project aims
                      to incorporate electric motor/generators into a commercial
                      turbofan engine, supplementing power across various flight
                      phases. Recent milestones include completing initial tests
                      of hybrid components and baseline testing of the GE
                      Passport engine, which will be further tested with the new
                      hybrid components.
                    </p>
                    <p>
                      The hybrid engine leverages multiple energy sources, such
                      as jet fuel and sustainable aviation fuels combined with
                      electricity, potentially reducing fuel use by up to 5%
                      compared to conventional engines. This approach, akin to a
                      "Toyota Prius of the skies," aligns with the aviation
                      industry's ambitious goal of achieving net-zero emissions
                      by 2050.
                    </p>
                    <p>
                      Arjan Hegeman, GE Aerospace’s general manager of future
                      flight technologies, emphasized the importance of these
                      advancements, stating, "We’re advancing state-of-the-art
                      propulsion systems for next-generation commercial aircraft
                      with the goal to enhance efficiency and cut emissions
                      compared to today’s engines."
                    </p>
                    <p>
                      In addition to the hybrid electric engine, GE Aerospace,
                      in partnership with Safran (SAF.PA), is{" "}
                      <a
                        className="text-decoration-none"
                        href="https://senwellexports.com/"
                      >
                        developing
                      </a>{" "}
                      an open-bladed jet engine under the Revolutionary
                      Innovation for Sustainable Engines (RISE) program. This
                      engine aims to reduce fuel consumption and emissions by
                      20% for medium-haul jets, further contributing to
                      sustainable aviation.
                    </p>
                    <p>
                      GE Aerospace's ongoing efforts reflect a commitment to
                      transforming propulsion technology and reducing the
                      environmental impact of aviation, making meaningful
                      progress toward more sustainable air travel.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default News1;
