import React, { useState, useEffect } from "react";
import bgQuality from "../../Assets/Images/Quality/qualityBg.png";
import quality1 from "../../Assets/Images/Quality/Quality1.jpg";
import quality2 from "../../Assets/Images/Quality/Quality2.jpg";
import quality3 from "../../Assets/Images/Quality/Quality3.jpg";
import thumb from "../../Assets/Images/Quality/thumb.png";
import globe from "../../Assets/Images/Quality/globe.png";
import truck from "../../Assets/Images/Quality/truck.png";
import hand from "../../Assets/Images/Quality/hand.png";
import "./Quality.css";
import QualityS from "./QualityS";
import PrivacyPolicy from "../../Assets/Images/Quality/NEW Quality policy  group 6x3-new.jpg";
import Translation from "../../i18n";
import certificateImage from "../../Assets/Images/Quality/CERTIFICATE-NEW.jpg";
import certificate2 from "../../Assets/Images/Quality/Complex.png";
import { Helmet } from "react-helmet";

function Quality() {
  const { t } = Translation();
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLeft((prevLeft) => prevLeft - 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const qualityJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/quality",
    url: "https://senwellexports.com/quality",
    name: "Quality Assurance | Senwell Exports Pvt Ltd",
    description:
      "Senwell Exports Pvt Ltd ensures top quality through strict controls and industry certifications, guaranteeing every product meets high standards.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quality Assurance | Senwell Exports Pvt Ltd</title>
        <meta
          name="description"
          content="Senwell Exports Pvt Ltd ensures top quality through strict controls and industry certifications, guaranteeing every product meets high standards."
        />
        <link rel="canonical" href="https://senwellexports.com/quality" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Our Quality | Senwell Exports Pvt Ltd"
        />
        <meta
          property="og:description"
          content="Senwell Exports Pvt Ltd ensures top quality through strict controls and industry certifications, guaranteeing every product meets high standards."
        />
        <meta property="og:url" content="https://senwellexports.com/quality" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/qualityBg.4eeafcba5286e133899f.png"
        />
        <meta property="og:image:width" content="1519" />
        <meta property="og:image:height" content="451" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(qualityJsonLd)}
        </script>
      </Helmet>

      <section className="quality-first-section">
        <div className="first-bg-img">
          <img src={bgQuality} className="img-fluid" alt="bgQuality" />
          <div className="image-text">
            <h1>{t("OurQuality")}</h1>
          </div>
        </div>
      </section>

      <section className="quality-second-section">
        <div className="container mt-5">
          <div className="row d-flex flex-row align-items-center">
            <div className="qualityPolicyHeading mb-3">
              <h1>{t("Certification")}</h1>
              <div className="container mt-3 mb-4">
                <div className="row">
                  <div className="col-12 col-lg-6 px-0">
                    <img
                      src={certificateImage}
                      alt="img"
                      className="certificate-img"
                    />
                  </div>
                  <div className="col-12 col-lg-6 px-0">
                    <p class="intro-text">{t("proud")}</p>
                    <h3 class="certification-heading">{t("Guarantee")} :</h3>
                    <ul class="certification-list">
                      <li>{t("stringent")}</li>
                      <li>{t("empennages")}</li>
                      <li>{t("Adherence")}</li>
                    </ul>
                    <div className="row">
                      <div className="col-md-6 img-complex">
                        <img
                          src={certificate2}
                          alt="certificate2"
                          className="img-complex"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h1>
                {t("Quality")} <span>{t("Policy")}</span>
              </h1>
            </div>
            <img src={PrivacyPolicy} alt="img" />
          </div>

          <div className="row mt-5">
            <div className="col-md-8 my-2">
              <div className="quality-section-heading2 mt-2 mb-3">
                <h1>
                  {t("Quality")} <span>{t("assurance")}</span>
                </h1>
              </div>
              <div className="quality-section-para2">
                <p>{t("q-assurance")}</p>
              </div>
            </div>
            <div className="col-md-4 quality-second-section-img my-2">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="3000"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={quality1}
                      className="d-block w-100"
                      alt="quality1"
                    />
                  </div>

                  <div className="carousel-item active">
                    <img
                      src={quality2}
                      className="d-block w-100"
                      alt="quality2"
                    />
                  </div>

                  <div className="carousel-item">
                    <img
                      src={quality3}
                      className="d-block w-100"
                      alt="quality3"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quality-third-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <img
                    src={thumb}
                    className="img-thumbnail thumb"
                    alt="thumb"
                  />
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded ">
                  <h4>{t("Deffect")} </h4>
                  <p>{t("Deffect-text")}</p>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img src={globe} className="img-thumbnail mt-3" alt="img" />
                  </p>
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded  ">
                  <h4>{t("Continuous")}</h4>
                  <p>{t("Continuous-text")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 sepolicy">
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img
                      src={truck}
                      className="img-thumbnail mt-3 truck"
                      alt="img"
                    />
                  </p>
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded  ">
                  <h4>{t("Supply-Chain")}</h4>
                  <p>{t("Supply-Chain-text")}</p>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img
                      src={hand}
                      className="img-thumbnail hand mt-3"
                      alt="img"
                    />
                  </p>
                </div>
                <div className="quality-card  shadow bg-body-tertiary rounded ">
                  <h4>{t("Integrity")}</h4>
                  <p>{t("Integrity-text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QualityS />
    </>
  );
}

export default Quality;
