import React, { useState } from "react";
import "./InnerOil.css";
import { useParams } from "react-router-dom";
import JsonData from "../../../../Products.json";
import OrderForm from "../../OrderForm/OrderForm";

const InnerOil = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  const { typeId } = useParams();
  const selectedType = JsonData.sections
    .flatMap((section) => section?.types || [])
    .find((type) => type.id === Number(typeId));

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  if (!selectedType) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <div className="Inner-oil-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {loading && <div className="loader"></div>}
              <div className="service-block-6 mb-xl-30">
                <img
                  src={selectedType.imageInner}
                  alt={selectedType.title}
                  onLoad={handleImageLoad}
                  className="oilgas-fit"
                />
              </div>
            </div>

            <div
              className="col-lg-8 col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="service-block-6 mb-xl-30">
                <h2>{selectedType.title}</h2>
                <h2>{selectedType.title2}</h2> <br />
                {showPopup ? (
                  <div className="service-block mb-xl-30 position-relative">
                    <div className="text-start service-caption">
                      <button
                        className="btn btn btn-primary"
                        onClick={handleApplyNowClick}
                      >
                        Request a quotation
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="order-form-pop">
                    <div
                      className="order-form-overlay"
                      onClick={handleApplyNowClick}
                    ></div>
                    <OrderForm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerOil;
