import React from "react";
import "./ElectricalProducts.css";
import TerminalB from "../../../Assets/Images/Products/Terminal Blocks.png";
import FastenerF from "../../../Assets/Images/Products/Fasteners And Fixings.png";
import ElectricalA from "../../../Assets/Images/Products/Electrical Accessories.png";
import Img3 from "../../../Assets/Images/Products/img3.png";
import CableG from "../../../Assets/Images/Products/Cables And Gland (1).png";
import Earthing from "../../../Assets/Images/Products/Earthing Accessories.png";
import CableT from "../../../Assets/Images/Products/NewCableTies.png";
import CastingP from "../../../Assets/Images/Products/Casting Product.png";
import MetalE from "../../../Assets/Images/Products/Metal Enclosure.png";
import { Link } from "react-router-dom";
import HomeVideo from "../../../Assets/Images/Video/Energy.mp4";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";

function ElectricalProducts() {
  const electricalProductsJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/energy",
    url: "https://senwellexports.com/energy",
    name: "Components used in Energy Sector | Senwell Exports Pvt Ltd",
    description:
      "Explore Senwell Exports Pvt Ltd’s extensive range of energy components, featuring innovative solutions and high-quality manufacturing standards.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
    video: {
      "@type": "VideoObject",
      name: "Energy Video",
      description: "A video showcasing the enery of Senwell Exports Pvt Ltd.",
      uploadDate: "2024-09-26",
      contentUrl:
        "https://senwellexports.com/static/media/Energy.1c825aec8b230d743cc2.mp4",
      embedUrl: "https://senwellexports.com/energy#video",
      duration: "PT6S",
      width: 1280,
      height: 720,
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Components used in Energy Sector | Senwell Exports Pvt Ltd
        </title>
        <meta
          name="description"
          content="Explore Senwell Exports Pvt Ltd’s extensive range of energy components, featuring innovative solutions and high-quality manufacturing standards."
        />
        <link rel="canonical" href="https://senwellexports.com/energy" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Energy Sector | Senwell Exports Pvt Ltd"
        />
        <meta
          property="og:description"
          content="Explore Senwell Exports Pvt Ltd’s extensive range of energy components, featuring innovative solutions and high-quality manufacturing standards."
        />
        <meta property="og:url" content="https://senwellexports.com/energy" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/I3.d14c855b06046d4fd269.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(electricalProductsJsonLd)}
        </script>
      </Helmet>
      <div className="video-container">
        <ReactPlayer
          url={HomeVideo}
          width="100%"
          height="100%"
          controls={false}
          playing
          loop
          muted
        />
      </div>
      <section className="product-section ">
        <div className="container">
          <div className="row justify-content-center product-row">
            <h1 className=" mt-5 mb-4">Energy</h1>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img
                  src={TerminalB}
                  className="card-img-top"
                  alt="TerminalImg"
                />
                <Link to="/terminal-blocks">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/terminal-blocks" className="card-button">
                  Terminal Block
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={FastenerF} className="card-img-top" alt="FastenerF" />
                <Link to="/fastener-fixing">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/fastener-fixing" className="card-button">
                  Fasteners And Fixings
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img
                  src={ElectricalA}
                  className="card-img-top"
                  alt="ElectricalA"
                />
                <Link to="/electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/electrical-accessories" className="card-button">
                  Electrical Accessories
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Img3} className="card-img-top" alt="Img3" />
                <Link to="/brass-electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/brass-electrical-accessories"
                  className="card-button"
                >
                  Brass Electrical Accessories
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableG} className="card-img-top" alt="CableG" />
                <Link to="/CableTies">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies" className="card-button">
                  Cables And Gland
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Earthing} className="card-img-top" alt="Earthing" />
                <Link to="/coppper-brass-earthing-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/coppper-brass-earthing-accessories"
                  className="card-button"
                >
                  Earthing Accessories
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableT} className="card-img-top" alt="CableT" />
                <Link to="/CableTies2">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies2" className="card-button">
                  Cable Ties
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CastingP} className="card-img-top" alt="CastingP" />
                <Link to="/casting-product">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/casting-product" className="card-button">
                  Casting Product
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={MetalE} className="card-img-top" alt="MetalE" />
                <Link to="/metal-enclosure-component">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/metal-enclosure-component" className="card-button">
                  Metal Enclosure
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ElectricalProducts;
