import React from "react";
import Machine6 from "../../Assets/Images/Infra/CoordinateMeasuring.png";

const CmmMachine = () => {
  return (
    <>
      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={Machine6}
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8 ">
            <table
              className="table  table-striped table-bordered table-responsive-md "
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    COORDINATE MEASURING MACHINE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ID No</td>
                  <td>SE-CMM-001</td>
                </tr>
                <tr>
                  <td>Make</td>
                  <td>HEXAGON</td>
                </tr>
                <tr>
                  <td>Machine Model</td>
                  <td>Global's Green</td>
                </tr>
                <tr>
                  <td>Machine Size</td>
                  <td>X-700MM Y-1000MM Z-700мм</td>
                </tr>
                <tr>
                  <td>LC</td>
                  <td>0.0001 MM</td>
                </tr>

                <tr>
                  <td>PROBE HEAD</td>
                  <td>HH-A-T7.5</td>
                </tr>
                <tr>
                  <td>PROBE HEAD SR.NO</td>
                  <td>2M002400</td>
                </tr>
                <tr>
                  <td>PROBE</td>
                  <td>SP25M</td>
                </tr>
                <tr>
                  <td>PROBE SR.NO</td>
                  <td>37E628/4UJ354</td>
                </tr>
                <tr>
                  <td>CERTIFICATE NO</td>
                  <td>40/HMIN/2023</td>
                </tr>
                <tr>
                  <td>CALIBRATION DATE</td>
                  <td>14-11-2023</td>
                </tr>
                <tr>
                  <td>CALIBRATION DUE DATE</td>
                  <td>13-11-2025</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CmmMachine;
