import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Home/Home";
import Header from "./Components/Header/Header";
import About from "./Components/About/About";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Components/ContactUs/ContactUs";
import Service from "./Components/Service/Service";
import Blog from "./Components/Blog/Blog";
import Cultural from "./Components/Blog/Blogs/CulturalSensivity";
import Market from "./Components/Blog/Blogs/MarketStratigies";
import OvercomingManufacturing from "./Components/Blog/Blogs/OvercomingManufacturing";
import CompresiveGuide from "./Components/Blog/Blogs/CompresiveGuide";
import UnlockingSuccess from "./Components/Blog/Blogs/UnlockingSuccess";
import Quality from "./Components/Quality/Quality";
import Infrastructure from "./Components/Infrastructure/Infrastructure";
import Career from "./Components/Career/Career";
import AeroSpace from "./Components/Products/AeroSpace/AeroSpace";
import TerminalBlock from "./Components/Products/ElectricalProduct/TerminalBlock/TerminalBlock";
import InnerTerminal from "./Components/Products/ElectricalProduct/InnerTerminal/InnerTerminal";
import OilandGas from "./Components/Products/Oil&Gas/OilandGas";
import InnerOil from "./Components/Products/Oil&Gas/InnerOil/InnerOil";
import Fastener from "./Components/Products/ElectricalProduct/Fasteners/Fastener";
import InnerFastener from "./Components/Products/ElectricalProduct/Fasteners/InnerFastener/InnerFastener";
import SubInner from "./Components/Products/ElectricalProduct/Fasteners/SubInner/SubInner";
import ProductDetails from "./Components/Products/ElectricalProduct/CableTies/InnerCable/ProductDetails";
import ElectricalAccess from "./Components/Products/ElectricalProduct/ElectricalAccess/ElectricalAccess";
import InnerAccess from "./Components/Products/ElectricalProduct/ElectricalAccess/InnerAccess/InnerAccess";
import SubInnerAccess from "./Components/Products/ElectricalProduct/ElectricalAccess/SubInnerAccess/SubInnerAccess";
import BrassElectrical from "./Components/Products/ElectricalProduct/BrassElectrical/BrassElectrical";
import InnerBrass from "./Components/Products/ElectricalProduct/BrassElectrical/InnerBrass/InnerBrass";
import SubInnerBrass from "./Components/Products/ElectricalProduct/BrassElectrical/SubInnerBrass/SubInnerBrass";
import CableTies from "./Components/Products/ElectricalProduct/CableTies/CableTies";
import InnerCable from "./Components/Products/ElectricalProduct/CableTies/InnerCable/InnerCable";
import SubInnerCable from "./Components/Products/ElectricalProduct/CableTies/SubInnerCable/SubInnerCable";
import EarthinAccesories from "./Components/Products/ElectricalProduct/BrassElectrical/EarthinAccesories/EarthinAccesories";
import InnerEarthing from "./Components/Products/ElectricalProduct/BrassElectrical/EarthinAccesories/InnerEarthing/InnerEarthing";
import SubInnerEarth from "./Components/Products/ElectricalProduct/BrassElectrical/EarthinAccesories/SubInnerEarth/SubInnerEarth";
import CableTies2 from "./Components/Products/ElectricalProduct/CableTies2/CableTies2";
import InnerCableTies2 from "./Components/Products/ElectricalProduct/CableTies2/InnerCableTies2/InnerCableTies2";
import SubInnerTies2 from "./Components/Products/ElectricalProduct/CableTies2/SubInnerTies2/SubInnerTies2";
import CastingProduct from "./Components/Products/ElectricalProduct/CastingProduct/CastingProduct";
import InnerCastingProduct from "./Components/Products/ElectricalProduct/CastingProduct/InnerCastingProducts/InnerCastingProduct";
import SubInnerCasting from "./Components/Products/ElectricalProduct/CastingProduct/SubInnerCasting/SubInnerCasting";
import MetalE from "./Components/Products/ElectricalProduct/MetalE/MetalE";
import InnerMetal from "./Components/Products/ElectricalProduct/MetalE/InnerMetal/InnerMetal";
import TurnedMilled from "./Components/Products/ElectricalProduct/TurnedMilled/TurnedMilled";
import InnerTurned from "./Components/Products/ElectricalProduct/TurnedMilled/InnerTurned/InnerTurned";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import ElectricalProducts from "./Components/Products/ElectricalProduct/ElectricalProducts";
import Precision from "./Components/Blog/Blogs/Precision";
import Privacy from "./Components/Privacy/Privacy";
import Login from "../src/Admin/Login/Login";
import { useTranslation } from "react-i18next";
import Csr from "./Components/CSR/Csr";
import { Helmet } from "react-helmet";
import CmmMachine from "./Components/Infrastructure/CmmMachine";
import ThankYouPage from "./Components/ThankYouPage/ThankYouPage";
import ElevatePrecision from "./Components/Blog/Blogs/ElevatePrecision";
import VmmMachine from "./Components/Infrastructure/VmmMachine";
import HmmMachine from "./Components/Infrastructure/HmmMachine";
import SrtMachine from "./Components/Infrastructure/SrtMachine";
import Vmc3axismachine from "./Components/Infrastructure/Vmc3axisMachine";
import Vmc4axismachine from "./Components/Infrastructure/Vmc4axismachine";
import Cnc02machine from "./Components/Infrastructure/Cnc02Machine";
import MasteringPrecision from "./Components/Blog/Blogs/MasteringPrecision";
import FromVisioToReality from "./Components/Blog/Blogs/FromVisionToReality";
import News from "./Components/News/News";
import News1 from "./Components/News/News1/News1";
import AerospaceBlogs from "./Components/Blog/Blogs/AerospaceBlogs";
import CertifiedBlog from "./Components/Blog/Blogs/CertifiedBlog";
import PrecesionManufactering from "./Components/Blog/Blogs/PrecesionManufactering";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || Home</title>
        <link rel="canonical" href="https://senwellexports.com/" />
      </Helmet>
      <div>
        <ScrollToTopButton />
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/service" element={<Service />} />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001:2015-Certification"
              element={<CertifiedBlog />}
            />{" "}
            <Route
              path="/blog/The-Role-of-Precision-Manufacturing-in-Delivering-High-Quality-Products"
              element={<PrecesionManufactering />}
            />
            <Route path="/blog/cultural-sensitivity" element={<Cultural />} />
            <Route
              path="/blog/Key-Factors-to-Consider-When-Choosing-an-Aerospace-Manufacturing-Company"
              element={<AerospaceBlogs />}
            />
            <Route path="/blog/Market" element={<Market />} />
            <Route path="/blog/precision-machining" element={<Precision />} />
            <Route
              path="/blog/mastering-precision"
              element={<MasteringPrecision />}
            />
            <Route
              path="/blog/OvercomingManufacturing"
              element={<OvercomingManufacturing />}
            />
            <Route path="/blog/CompresiveGuide" element={<CompresiveGuide />} />
            <Route
              path="/blog/UnlockingSuccess"
              element={<UnlockingSuccess />}
            />
            <Route
              path="/blog/From-vision-to-reality"
              element={<FromVisioToReality />}
            />
            <Route path="/quality" element={<Quality />} />
            <Route path="/news" element={<News />} />
            <Route
              path="/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
              element={<News1 />}
            />
            <Route path="/infrastructure" element={<Infrastructure />} />
            <Route path="/career" element={<Career />} />
            <Route path="/aerospace-industries" element={<AeroSpace />} />
            <Route path="/energy" element={<ElectricalProducts />} />
            <Route path="/terminal-blocks" element={<TerminalBlock />} />
            <Route
              path="/inner-terminal-blocks/:pageName"
              element={<InnerTerminal />}
            />
            <Route path="/fastener-fixing" element={<Fastener />} />
            <Route
              path="/fastener-fixing/:typeId"
              element={<InnerFastener />}
            />
            <Route
              path="/fastner-subinner/:categoryId/:typeId"
              element={<SubInner />}
            />
            <Route
              path="/electrical-accessories"
              element={<ElectricalAccess />}
            />
            <Route
              path="/inner-accessories/:typeId"
              element={<InnerAccess />}
            />
            <Route
              path="/subinner-accessories/:categoryId/:typeId"
              element={<SubInnerAccess />}
            />
            <Route
              path="/brass-electrical-accessories"
              element={<BrassElectrical />}
            />
            <Route
              path="/inner-brass-electrical-accessories/:typeId"
              element={<InnerBrass />}
            />
            <Route
              path="/sub-inner-brass/:categoryId/:typeId"
              element={<SubInnerBrass />}
            />
            <Route path="/CableTies" element={<CableTies />} />
            <Route path="/Inner-cableTies/:typeId" element={<InnerCable />} />
            <Route
              path="/Inner-cableTies-product/:categoryId/:typeId"
              element={<ProductDetails />}
            />
            <Route
              path="/sub-Inner-cableTies/:typeId"
              element={<SubInnerCable />}
            />
            <Route
              path="/coppper-brass-earthing-accessories"
              element={<EarthinAccesories />}
            />
            <Route
              path="/inner-coppper-brass-earthing-accessories/:typeId"
              element={<InnerEarthing />}
            />
            <Route
              path="/sub-inside/:categoryId/:typeId"
              element={<SubInnerEarth />}
            />
            <Route path="/CableTies2" element={<CableTies2 />} />
            <Route
              path="/inner-cableTies2/:typeId"
              element={<InnerCableTies2 />}
            />
            <Route
              path="/sub-inner-cableTies2/:categoryId/:typeId"
              element={<SubInnerTies2 />}
            />
            <Route path="/casting-product" element={<CastingProduct />} />
            <Route
              path="/innner-casting-product/:typeId"
              element={<InnerCastingProduct />}
            />
            <Route
              path="/sub-innner-casting-product/:categoryId/:typeId"
              element={<SubInnerCasting />}
            />
            <Route path="/metal-enclosure-component" element={<MetalE />} />
            <Route
              path="/inner-metal-enclosue/:typeId"
              element={<InnerMetal />}
            />
            <Route path="/turned-miled-forged" element={<TurnedMilled />} />
            <Route
              path="/inner-turned-miled-forged/:typeId"
              element={<InnerTurned />}
            />
            <Route path="/oil-gas/*" element={<OilandGas />} />
            <Route path="/oil-gas/:typeId" element={<InnerOil />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/csr" element={<Csr />} />
            <Route exact path="/admin/login" element={<Login />} />
            <Route path="/cmmmachine" element={<CmmMachine></CmmMachine>} />
            <Route path="/vmmmachine" element={<VmmMachine />} />
            <Route path="/hmmmachine" element={<HmmMachine />} />
            <Route
              path="/blog/elevateprecision"
              element={<ElevatePrecision />}
            />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/srtmachine" element={<SrtMachine />} />
            <Route path="/vmc3axismachine" element={<Vmc3axismachine />} />
            <Route path="/vmc4axismachine" element={<Vmc4axismachine />} />
            <Route path="/cnc02machine" element={<Cnc02machine />} />
            <Route path="*" element={<ErrorPage></ErrorPage>}></Route>
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
};

export default App;
