import React from "react";
import "./HomeProduct.css";
import { Link } from "react-router-dom";
import Translation from "../../i18n";
import ProductItem from "../HomeProduct/HomeProducts.json";

function Product() {
  const images = {
    Aero4: require("../../Assets/Images/Products/Aerospace/Aero4.jpg"),
    Aero2: require("../../Assets/Images/Products/Aerospace/Aero2.jpg"),
    Aero9: require("../../Assets/Images/Products/Aerospace/Aero9.jpg"),
    Aero11: require("../../Assets/Images/Products/Aerospace/Aero11.jpg"),
    Aero15: require("../../Assets/Images/Products/Aerospace/Aero15.jpg"),
    Aero17: require("../../Assets/Images/Products/Aerospace/Aero17.jpg"),
    Aero18: require("../../Assets/Images/Products/Aerospace/Aero18.jpg"),
    Aero19: require("../../Assets/Images/Products/Aerospace/Aero19.jpg"),
    Aero20: require("../../Assets/Images/Products/Aerospace/Aero20.jpg"),
    Aero25: require("../../Assets/Images/Products/Aerospace/Aero25.jpg"),
    Aero26: require("../../Assets/Images/Products/Aerospace/Aero26.jpg"),
    Aero30: require("../../Assets/Images/Products/Aerospace/Aero30.jpg"),
  };
  const { t, i18n } = Translation();
  return (
    <>
      <section className="product-section ">
        <div className="container">
          <div className="row justify-content-center product-row">
            <h1 className="mt-3 mb-4">
              {t("our")} <span>{t("Products")}</span>
            </h1>

            <div className="home-products-container mt-5 mb-5">
              <div className="row g-5">
                {ProductItem.map((product) => (
                  <div key={product.id} className="col-lg-4 col-md-6">
                    <Link
                      to="/aerospace-industries"
                      className="home-product-image-container"
                    >
                      <img
                        src={images[product.image]}
                        alt={product.title}
                        className="home-product-image"
                      />
                      <div className="home-overlay-text">
                        <div className="home-overlay-inner-text">
                          <span>{product.title}</span> <br />
                          <span>(Material-{product.material})</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Product;
