import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AboutHome from "../../Assets/Images/AboutUs/aboutHome.jpg";
import CountLessBanenr from "../../Assets/Images/AboutUs/core products-2.jpg";
import countless from "../../Assets/Images/AboutUs/Countless.png";
import crouselImg1 from "../../Assets/Images/AboutUs/about1.png";
import crouselImg2 from "../../Assets/Images/AboutUs/about2.png";
import crouselImg3 from "../../Assets/Images/AboutUs/about3.png";
import mission from "../../Assets/Images/AboutUs/mission.png";
import vision from "../../Assets/Images/AboutUs/vision.png";
import team from "../../Assets/Images/AboutUs/team.png";
import quality from "../../Assets/Images/AboutUs/quality.png";
import whyImg from "../../Assets/Images/AboutUs/choose.png";
import Titanium from "../../Assets/Images/AboutUs/Titanium-2.jpg";
import Inconel from "../../Assets/Images/AboutUs/Inconel-2.jpg";
import Stainless from "../../Assets/Images/AboutUs/Stainless Steel.webp";
import Aluminium from "../../Assets/Images/AboutUs/Aluminium.webp";
import brass from "../../Assets/Images/AboutUs/Brass.webp";
import Copper from "../../Assets/Images/AboutUs/Copper.webp";
import infra1 from "../../Assets/Images/AboutUs/infra1.jpg";
import infra2 from "../../Assets/Images/Infra/NewMachine1.jpg";
import infra3 from "../../Assets/Images/Infra/NewMachine3.jpg";
import infra4 from "../../Assets/Images/Infra/NewMachine5.jpg";

import Translation from "../../i18n";
import "./About.css";

const About = () => {
  const { t } = Translation();

  const aboutJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/about",
    url: "https://senwellexports.com/about",
    name: "About Us | Senwell Exports Pvt Ltd",
    description:
      "Senwell Exports delivers high-quality precision machining components globally for Aerospace, Defence, Energy, and Oil & Gas sectors and on-time delivery.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Export Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Precision Machining Components Manufacturer</title>
        <meta
          name="description"
          content="Senwell Exports delivers high-quality precision machining components globally for Aerospace, Defence, Energy, and Oil & Gas sectors and on-time delivery."
        />
        <link rel="canonical" href="https://senwellexports.com/about" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="About Us | Countless Products One Purpose"
        />
        <meta
          property="og:description"
          content="Senwell Exports Private Limited. is a dynamic and vibrant enterprise specializing in the production and exportation of top-tier non-ferrous metal components and electrical accessories."
        />
        <meta property="og:url" content="https://senwellexports.com/about" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/aboutHome.9c922303951a572db844.png"
        />
        <meta property="og:image:width" content="293" />
        <meta property="og:image:height" content="396" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">
          {JSON.stringify(aboutJsonLd)}
        </script>
      </Helmet>
      <section className="about-section-home">
        <div className="about-section-img">
          <img src={AboutHome} className="img-fluid" alt="AboutHome" />
          <div className="text-overlay">
            <h2>{t("AboutUs")}</h2>
          </div>
        </div>
      </section>

      <section className="about-section-second">
        <div className="container shadow p-3 rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-md-4 col-sm-8">
              <img
                src={CountLessBanenr}
                className="img-fluid mt-5"
                alt="img"
                style={{ width: "100%", marginTop: "0px" }}
              />
            </div>
            <div className="col-xl-8 col-md-8 ">
              <div className="about-section-second-heading ">
                <h2> {t("Countless")} </h2>
                <h2 className="mx-2 purpose">{t("onePurpose")}</h2>
              </div>
              <div className="about-section-second-para mt-4">
                <p>{t("counttext")}</p>
                <p>{t("counttext1")}</p>
                <p>{t("counttext3")}</p>
              </div>
              <div className="about-section-second-icons-section  mt-4">
                <div className="row">
                  <img src={countless} alt="countless" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-third-opportunity mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="heading">
                <h2>{t("big")}</h2>
                <h2 className="mx-2 opportunity">{t("business")}</h2>
              </div>
              <div className="heading-para ">
                <p>{t("growthtext")}</p>
                <p>{t("growthtext1")}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="lightbox">
                <div className="row lastimg" style={{ marginTop: "54px" }}>
                  <div className="col-lg-6">
                    <img
                      src={crouselImg1}
                      alt="Table Full of Spices"
                      className="w-100 h-50 mb-2 mb-md-4 shadow-1-strong "
                    />
                    <img
                      src={crouselImg2}
                      alt="Coconut with Strawberries"
                      className="w-100 h-50  shadow-1-strong "
                    />
                  </div>
                  <div className="col-lg-6 last-img last-mg">
                    <img
                      src={crouselImg3}
                      alt="Dark Roast Iced Coffee"
                      className="w-100   shadow-1-strong "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 ">
              <div className="about-section-mission-img">
                <img src={mission} alt="mission" />
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("mision")}</div>
                  <p>{t("missiontext")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col1">
              <div className="about-section-mission-img">
                <img src={vision} className="img-thumbnail" alt="vision" />
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("vision")}</div>
                  <p>{t("visiontext")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col">
              <div className="about-section-mission-img">
                <img src={team} className="img-thumbnail" alt="team"></img>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="custom-button mb-2">{t("Management")}</div>
                  <p>{t("Management-text")}</p>
                  <ol>
                    <li>Process Ownership</li>
                    <li>Guaranteed Savings</li>
                    <li>Performance Efficiency</li>
                    <li>Design Optimization</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mission-col">
              <div className="about-section-mission-img">
                <img
                  src={quality}
                  className="img-thumbnail"
                  alt="quality"
                ></img>
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("Quality")}</div>
                  <p>{t("quality-text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whychoose-sections">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 why-leftSide">
              <h2>
                {t("why")} <span>{t("Choose")}</span>
              </h2>
              <p>{t("Choose-text")}</p>
              <div className="row section4-row2">
                <div className="col-lg-4  my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("focus")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Competative")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Precision")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Exceptional")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Continuou")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Superior")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Packaging")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3  ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Deliver")} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 section2-col-2 why-img">
              <img src={whyImg} alt="img" className="mt-5" />
            </div>
          </div>
        </div>
      </section>

      <section className="material-section">
        <div className="container">
          <div className="section-title">
            <h2>{t("materials")}</h2>
            <div className="row">
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3">
                <div>
                  <div className="m-image">
                    <img src={Titanium} alt="Titanium" />
                  </div>
                </div>
                <div className="lower-content-titanium">
                  <h4>{t("Titanium")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3">
                <div>
                  <div className="m-image">
                    <img src={Inconel} alt="Inconel" />
                  </div>
                </div>
                <div className="lower-content-Inconel">
                  <h4>{t("Inconel")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3">
                <div>
                  <div className="m-image">
                    <img src={Stainless} alt="Stainless" />
                  </div>
                </div>
                <div className="lower-content-copper">
                  <h4>{t("Stainless-Steel")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={Aluminium} alt="Aluminium" />
                  </div>
                </div>
                <div className="lower-content-Alu">
                  <h4>{t("Aluminium")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={brass} alt="brass" />
                  </div>
                </div>
                <div className="lower-content-brass">
                  <h4>{t("Brass")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={Copper} alt="Copper" />
                  </div>
                </div>
                <div className="lower-content-copper">
                  <h4>{t("Copper")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-infrastructure-section mt-3">
        <div className="container infrastructure-section">
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "50px" }}>
              <div className="infra-heading">
                <h1>{t("our")}</h1>
                <h1 className="infra">{t("Infrastructure")}</h1>
              </div>
              <div className="infra-para mt-3">
                <p>{t("infratext1")}</p>
              </div>

              <div className="infra-readMore">
                <Link to="/infrastructure" className="inner-infra">
                  {t("ReadMore")}
                </Link>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="row infra-img-section mt-4">
                <div className="col-md-4 p-1">
                  <img
                    src={infra1}
                    className="img-thumbnail h-100"
                    alt="infra"
                  />
                </div>
                <div className="col-md-8 p-1">
                  <img
                    src={infra2}
                    className="img-thumbnail h-100"
                    alt="infra"
                  />
                </div>
              </div>
              <div className="row infra-img-section">
                <div className="col-md-6 p-1">
                  <img
                    src={infra3}
                    className="img-thumbnail h-100"
                    alt="infra"
                  />
                </div>
                <div className="col-md-6 p-1">
                  <img
                    src={infra4}
                    className="img-thumbnail h-100"
                    alt="infra"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  );
};

export default About;
